import { FC, Fragment, useEffect, useState } from "react";
import { Text, Card, Badge, Button, Group, Checkbox } from "@mantine/core";
import { IconClockHour2, IconCircle, IconCircleFilled, IconChefHat } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import "./Recipe.css";
import axios from "axios";

const Recipe: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [recipe, setRecipe] = useState<any>();
  const [difficultyList, setDifficultyList] = useState<number[]>([]);

  const handleRecipe = async () => {
    if (!id) navigate("*");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/recipe/${id}?authorization=${process.env.REACT_APP_API_AUTHORIZATION}`
      );
      setRecipe(response.data);
      setDifficultyList(Array.from({ length: response.data.difficulty }, (_, i) => i));
    } catch (error) {
      // console.error(error);
      navigate("*");
    }
  };

  const backToHome = async () => {
    navigate("/");
  };

  const refreshData = async () => {
    await handleRecipe();
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <div className="recipeContainer">
      <div className="backToRecipesDiv">
        <Button color="blue" mt="md" radius="md" className="backToRecipes" onClick={backToHome}>
          Voltar para as receitas
        </Button>
      </div>
      <hr style={{ color: "#212429" }} />
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        key={recipe && recipe.id}
        className="m-auto cardOneRecipe"
      >
        <Card.Section>
          <div
            style={{
              backgroundSize: "100% 100%",
              backgroundImage: `url(${recipe && process.env.REACT_APP_API_UPLOAD + recipe.image})`,
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "600px",
            }}
          ></div>
        </Card.Section>
        <div className="recipeInfo">
          <Text size="sm" c="dimmed">
            <IconClockHour2 size={16} /> {recipe && recipe.time}
          </Text>
          <Text size="sm" c="dimmed">
            | Dificuldade
            {difficultyList &&
              difficultyList.map((item: number, index: number) => {
                return (
                  <Fragment key={index}>
                    <IconCircleFilled size={16} key={index} />
                  </Fragment>
                );
              })}
            {recipe && recipe.difficulty && recipe.difficulty === 1 && (
              <>
                <IconCircle size={16} />
                <IconCircle size={16} />
              </>
            )}
            {recipe && recipe.difficulty && recipe.difficulty === 2 && <IconCircle size={16} />}
          </Text>
          <Text size="sm" c="dimmed">
            | <IconChefHat size={16} /> Rendimento {recipe && recipe.amount}
          </Text>
        </div>
        <Group justify="space-between" mt="md" mb="xs">
          <Text fw={500} size="xl">
            {recipe && recipe.name}
          </Text>
          <Badge color="pink">{recipe && recipe.category}</Badge>
        </Group>
        {recipe &&
          recipe.ingredients &&
          recipe.ingredients.map((iterator: { step: string; quantity: string[] }, index: number) => {
            return (
              <Fragment key={index}>
                <Text c="blue">INGREDIENTES: {iterator.step}</Text>
                {iterator.quantity.map((item, index) => {
                  return <Checkbox label={item} color="violet" mb="xs" key={index} />;
                })}
              </Fragment>
            );
          })}
        {recipe &&
          recipe.preparation &&
          recipe.preparation.map((iterator: { step: string; label: string[] }, index: number) => {
            return (
              <Fragment key={index}>
                <Text c="blue" key={index}>
                  MODO DE PREPARO: {iterator.step}
                </Text>
                {iterator.label.map((item: string, index) => {
                  return <Checkbox label={item} color="violet" mb="xs" key={index} />;
                })}
              </Fragment>
            );
          })}
        <Text size="xs" c="dimmed">
          Dica: marque os itens conforme for realizando a receita para não se perder <code>;)</code>
        </Text>
      </Card>
    </div>
  );
};

export default Recipe;
