import React from "react";
import "./NotFound.css";

const NotFound: React.FC = () => {
  return (
    <div className="not-found">
      <i className="fa-solid fa-triangle-exclamation warning"></i>
      <h1>Página não encontrada. (404)</h1>
    </div>
  );
};

export default NotFound;
