import React, { useEffect, useState } from "react";
import { Input, Text, Card, Image, Badge, Button, Group } from "@mantine/core";
import { IconSearch, IconClockHour2 } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Home.css";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState([]);
  const [recipesFiltered, setRecipeFiltered] = useState<string>("");

  const goToRecipe = async (id: number) => {
    navigate(`/recipe/${id}`);
  };

  const handleRecipes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/recipes?authorization=${process.env.REACT_APP_API_AUTHORIZATION}`
      );
      setRecipes(response.data);
    } catch (error) {
      // console.error(error);
    }
  };

  const refreshData = async () => {
    await handleRecipes();
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <>
      <div className="recipesContainer">
        <h3 className="recipesTitle">
          Olá, espero que encontre a
          <Text
            style={{ fontSize: "1.5rem" }}
            fw={900}
            variant="gradient"
            gradient={{ from: "grape", to: "pink", deg: 90 }}
          >
            {" "}
            melhor receita{" "}
          </Text>
          que procura <code>;)</code>
        </h3>
        <hr style={{ color: "#212429" }} />
        <Input
          className="filterRecipes"
          placeholder="Pesquisar receita..."
          leftSection={<IconSearch size={16} />}
          value={recipesFiltered}
          onChange={(event) => setRecipeFiltered(event.currentTarget.value)}
        />
        <hr style={{ color: "#212429" }} />
        <div className="cardRecipes">
          {recipes &&
            recipes
              .filter(
                (e: any) =>
                  e.name?.toLowerCase().includes(recipesFiltered) ||
                  e.name?.toUpperCase().includes(recipesFiltered) ||
                  recipesFiltered === ""
              )
              .map((recipe: any) => {
                return (
                  <Card
                    shadow="sm"
                    padding="lg"
                    radius="md"
                    withBorder
                    className="cardRecipe"
                    key={recipe.id}
                  >
                    <Card.Section>
                      <Image
                        src={process.env.REACT_APP_API_UPLOAD + recipe.image}
                        height={200}
                        alt="Sem imagem"
                      />
                    </Card.Section>
                    <Group justify="space-between" mt="md" mb="xs">
                      <Text fw={500} size="lg">
                        {recipe.name}
                      </Text>
                      <Badge color="pink">{recipe.category}</Badge>
                    </Group>
                    <Text c="blue">{recipe.description}</Text>
                    <Text size="sm" c="dimmed">
                      <IconClockHour2 size={16} /> {recipe.time}
                    </Text>
                    <div className="recipeDetails">
                      <Button
                        color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                        onClick={() => goToRecipe(recipe.id)}
                      >
                        Ver receita completa
                      </Button>
                    </div>
                  </Card>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default Home;
