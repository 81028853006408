import Router from "./routes/router";
import { createRoot } from "react-dom/client";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "./index.css";

const domNode = document.getElementById("main")!;
const root = createRoot(domNode);
root.render(
  <MantineProvider>
    <Router />
  </MantineProvider>
);
